export default [
  {
    media: { icon: ['fab', 'sass'], color: 'info', className: 'fs-4' },
    title: 'Gestión de Poryectos TI',
    description:
      'Evaluamos y desarrollamos tus proyectos de TI para garantizar la seguridad de tu negocio.'
  },
  {
    media: { icon: ['fab', 'node-js'], color: 'success', className: 'fs-5' },
    title: 'Desarrollo Llave en Mano',
    description:
      'Desarrollamos tu idea de software con la mejor tecnología disponible.'
  },
  {
    media: { icon: ['fab', 'gulp'], color: 'danger', className: 'fs-6' },
    title: 'Servicios Integrados',
    description:
      'Nuestra solución es la mejor combinación de tecnología y experiencia.'
  }
];
